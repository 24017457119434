<template>
  <div>
    <v-toolbar flat class="blue-grey lighten-4 font-weight-light">
      <v-toolbar-title>Consulta do protocolo {{ protocolo }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form>
      <v-container>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="dadosRequerente.assunto"
              label="Assunto"
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="6"
          >
            <v-text-field
              v-model="dadosRequerente.requerente"
              label="Requerente"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="dadosRequerente.dataEntrada"
              label="Data de Entrada"
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="dadosRequerente.placa"
              label="Placa"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
export default {
  name: 'DadosProtocolo',
  props: {
    dadosRequerente: {},
    protocolo: {},
  },
};
</script>
