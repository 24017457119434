<template>
  <v-simple-table class="pb-10">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          Data de Andamento
        </th>
        <th class="text-left">
          Setor
        </th>
        <th class="text-left">
          Responsável
        </th>
        <th class="text-left">
          Situação
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="andamento in historico"
        :key="andamento.dt_entrada"
      >
        <td>{{ andamento.dt_andamento }}
        </td>
        <td>{{ andamento.nm_descricao }}</td>
        <td>{{ andamento.nm_funcionario }}</td>
        <td>{{ andamento.nm_fase }}</td>
      </tr>
      </tbody>
    </template>
    <v-spacer/>
  </v-simple-table>
</template>

<script>

export default {
  name: 'Items',
  props: ['historico'],
};
</script>

<style scoped>

</style>
