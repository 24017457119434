<template>
  <v-app-bar
      app
      color="#E8E8E8"
      dark
      elevation="0"
  >
    <div class="header d-flex align-center">
      <img src="../assets/logo.png" height="50" width="120"/>
    </div>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>
<script>
export default {
  name: 'Header',
};
</script>
<style>
.header {
  font-size: 20px;
}
</style>
