<template>
  <v-app>
    <Header/>
    <v-main>
      <v-container class="d-flex flex-column">
        <Card
          class="mt-15"
          @carrega-historico="carregaDados"
          @limpa-formulario="limpar"
          @numero-protocolo="setaProtocolo"
        />
        <transition name="fade">
          <div v-if="!permissao || semResultados">
            <Alerta :tipoAlerta=tipoAlerta />
          </div>
          <div v-if="dadosCarregados">
            <DadosProtocolo :dados-requerente="dadosRequerente" :protocolo="protocolo"/>
            <Items :historico="historico"/>
          </div>
        </transition>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>

import Header from '@/components/Header.vue';
import Card from '@/components/Card.vue';
import Footer from '@/components/Footer.vue';
import Items from '@/components/Items.vue';
import Alerta from '@/components/Alerta.vue';
import DadosProtocolo from '@/components/DadosProtocolo.vue';

export default {
  name: 'App',
  data() {
    return {
      dadosRequerente: {
        assunto: '',
        requerente: '',
        dataEntrada: '',
        placa: '',
      },
      tipoAlerta: '',
      permissao: true,
      semResultados: false,
      protocolo: '',
      historico: [],
      dadosCarregados: false,
    };
  },
  methods: {
    limpar() {
      this.historico = [];
      this.protocolo = '';
      this.dadosCarregados = false;
      this.permissao = true;
      this.tipoAlerta = '';
      this.semResultados = false;
    },
    carregaDados(dados) {
      this.historico = [];
      this.permissao = true;
      this.semResultados = false;
      this.tipoAlerta = '';
      if (dados[0].length) {
        this.dadosRequerente.assunto = dados[0][0].ds_assunto;
        this.dadosRequerente.requerente = dados[0][0].nm_requerente;
        this.dadosRequerente.dataEntrada = dados[0][0].dt_entrada;
        this.dadosRequerente.placa = dados[0][0].nu_placa;
        dados[0].map((andamento) => {
          this.historico.push(andamento);
          return andamento;
        });
        if (this.historico[0].cd_empresa !== 3) {
          this.permissao = false;
          this.tipoAlerta = 'info';
        }
        if (!this.permissao) {
          this.dadosCarregados = false;
          this.historico = [];
        } else {
          this.dadosCarregados = true;
        }
      } else {
        this.semResultados = true;
        this.tipoAlerta = 'warning';
      }
    },
    setaProtocolo(entradaProtocolo) {
      this.protocolo = entradaProtocolo;
    },
  },

  components: {
    DadosProtocolo,
    Alerta,
    Items,
    Footer,
    Card,
    Header,
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
