<template>
  <v-card class="search-card mx-auto"
          elevation="15"
          :loading="loading"
          width="100%"
  >
    <v-form
      ref="form"
      v-model="form"
      lazy-validation
    >
      <v-container>
        <v-card-title>
          Consulta Pública
        </v-card-title>
        <v-card-subtitle>
          Para obter informações acerca do andamento de seu processo, informe abaixo o protocolo e
          clique em Buscar.
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="protocolo"
            label="Protocolo"
            hint="Exemplo de protocolo: 00016.099030/2021-9"
            @click="limpaEntradaProtocolo"
            v-mask="'#####.######/####-#'"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mr-4"
            color="primary"
            @click="consultaProtocolo(protocolo)"
          >
            Consultar
          </v-btn>
          <v-btn @click.prevent="limparFormulario">
            Limpar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Card',
  data() {
    return {
      loading: false,
      historico: [],
      protocolo: '',
      form: '',
    };
  },
  methods: {
    limparFormulario() {
      this.protocolo = '';
      this.historico = [];
      this.$emit('limpa-formulario');
    },
    limpaEntradaProtocolo() {
      this.protocolo = '';
    },
    consultaProtocolo() {
      this.historico = [];
      this.loading = true;
      axios.get(`https://appcodata.pb.gov.br/isis-api/api/v1/sgp/consulta?protocolo=${this.protocolo}`)
        .then((res) => {
          this.historico.push(res.data.result.data);
          this.$emit('carrega-historico', this.historico);
          this.$emit('numero-protocolo', this.protocolo);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="css" scoped>
.search-card {
  margin-top: 10em;
  margin-bottom: 5em;
  max-width: 800px;
}
</style>
