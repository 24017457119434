<template>
  <v-alert
    border="top"
    colored-border
    :type=tipoAlerta
    elevation="2"
  >
    <p v-if="tipoAlerta === 'info'">
      Para consulta pública de protocolos do seu órgão entre em contato com o Administrador.
    </p>
    <p v-if="tipoAlerta === 'warning'">
      Protocolo não encontrado.
    </p>
  </v-alert>
</template>
<script>
export default {
  name: 'AlertaPermissao',
  props: ['tipoAlerta'],
  data() {
    return {
      permissao: false,
      semResultados: false,
    };
  },
  computed: {
    setAlert(tipoAlerta) {
      console.log(tipoAlerta);
      return 'Hello';
    },
  },
};
</script>
